import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <img className={styles.logo} src="/images/logo.svg" alt="logo" />
      <div className={styles.site}>
        <p className={styles.topText}>сайт</p>
        <p className={styles.bottomText}>softndit.com</p>
      </div>
      <div className={styles.email}>
        <p className={styles.topText}>e-mail</p>
        <p className={styles.bottomText}>hi@softndit.com</p>
      </div>
      <div className={styles.telephone}>
        <p className={styles.topText}>телефон</p>
        <p className={styles.bottomText}>8 800 200 48 30</p>
      </div>
    </div>
  );
};

export default Footer;
