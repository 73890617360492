import React, { useState } from 'react'
import { InputText } from '../../UI/InputText';
import styles from './Lot.module.scss'

const Lot = ({name: nameInitial, hours: hoursInitial, budget: budgetInitial, content: contentInitial, onRemove, text, updateLot, id, language, currency}) => {
  const [name, setName] = useState(nameInitial);
  const [hours, setHours] = useState(hoursInitial);
  const [budget, setBudget] = useState(budgetInitial);
  const [content, setContent] = useState(contentInitial);

  React.useEffect(() => {
    updateLot(id, "name", name) 
  }, [name, id]);

  React.useEffect(() => {
    updateLot(id, "hours", hours) 
  }, [hours, id]);

  React.useEffect(() => {
    updateLot(id, "budget", budget) 
  }, [budget, id]);

  React.useEffect(() => {
    updateLot(id, "content", content) 
  }, [content, id]);

  return (
    <div className={!text ? styles.lot : styles.lotForPrint}>
      <div className={styles.info}>
        <p className={styles.name}>
          <InputText text={text} multiline value={name} setValue={e => setName(e.target.value)}/>
        </p>
        <p className={styles.hb}>
          {language == 'ru' ? 'часы' : 'hours'} <InputText text={text} value={hours} setValue={e => setHours(e.target.value)}/> <span>/</span> {currency != 'ru' ? (currency == 'usd' ? '$' : '€') : null}  {currency == 'rub' ? '₽' : null}<InputText text={text} value={budget} setValue={e => setBudget(e.target.value)}/> {currency == 'rub' ? '₽' : null}
        </p>
      </div>
      <p className={styles.content}>
        <InputText text={text} multiline value={content} setValue={e => setContent(e.target.value)}/>
      </p>

      {text ? null : <span onClick={onRemove} className={styles.delete}>{language == 'ru' ? 'Удалить' : 'Delete'}</span>}
    </div>
  )
}

export default Lot