import React, { useEffect, useState } from 'react'
import { InputText } from '../../UI/InputText'
import styles from './Total.module.scss'

const Total = ({name, content, special, text, handleTotal, inputName, specialBefore}) => {
  return (
    <div className={styles.total}>
      <p className={styles.name}>
        {name}
      </p>
      <p className={styles.content}>
      {special && specialBefore && special}

        <InputText text={text} value={content} setValue={e => {
          handleTotal({target: {name: inputName, value: e.target.value}})
          // setContent(e.target.value)
          }} />
        
        {special && !specialBefore && ' ' + special}
      </p>
      </div>
  )
}

export default Total