import React from "react";
import Lot from "../Lot/Lot";
import styles from "./Lots.module.scss";
const Lots = ({ lots, onAddLot, onRemoveLot, text, updateLot, language, currency }) => {
  return (
    <div className={styles.lots}>
      <div id="lots__wrapper" className={styles.gg}>
        {lots.map((lot) => (
          <Lot
            language={language}
            currency={currency}
            key={lot.id}
            updateLot={updateLot}
            text={text}
            onRemove={() => onRemoveLot(lot.id)}
            {...lot}
          />
        ))}
      </div>

      {text ? null : (
        <span onClick={onAddLot} className={styles.add}>
          {language == 'ru' ? 'Добавить' : 'Add'}
        </span>
      )}
    </div>
  );
};

export default Lots;
