import React from "react";
import { InputText } from "../../UI/InputText";
import { Text, Image, StyleSheet } from "@react-pdf/renderer";

import styles from "./Header.module.scss";

const Header = ({ text, date, setDate, isPdf }) => {
  if (isPdf) {
    const styles = StyleSheet.create({
      header: {
        width: "100%",
        display: "flex",
        backgroundColor: "#E4E4E4",
      },
    });
    return (
      <div style={styles.header}>
        
      </div>
    );
  }

  return (
    <div className={styles.header}>
      <div>
        <img src="/images/logo.svg" alt="logo" />
      </div>
      <p className={styles.text}>
        коммерческое предложение от{" "}
        <InputText
          text={text}
          value={date}
          setValue={(e) => setDate(e.target.value)}
        />
      </p>
    </div>
  );
};

export default Header;
