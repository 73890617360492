import React from "react";

import styles from "./Card.module.scss";

const Card = ({ children, ...restProps }) => {
  return (
    <div {...restProps} className={styles.card}>
      {children}
    </div>
  );
};

export default Card;
