import React from 'react'
import Total from '../Total/Total'
import totals from './totals.json'
import styles from './Totals.module.scss'

// [
//   {"name":"Команда", "content":"8"},
//   {"name":"Часы", "content":"810"},
//   {"name":"Стоимость", "content":"1 175 500 ₽"},
//   {"name":"Срок выполнения", "content":"65 дней"}
// ]

const Totals = ({ text, handleTotal, total, language, currency }) => {
  return (
    <div>
      <Total text={text} handleTotal={handleTotal} content={total.team} name={language == 'ru' ? 'Команда' : 'Team'} inputName="team" />
      <Total text={text} handleTotal={handleTotal} content={total.hours} name={language == 'ru' ? 'Часы' : 'Hours'} inputName="hours"/>
      <Total specialBefore={true} text={text} handleTotal={handleTotal} content={total.price} special={currency == 'rub' ? '₽' : (currency == 'usd' ? '$' : '€')} name={language == 'ru' ? 'Стоимость' : 'Budget'} inputName="price" />
      <Total specialBefore={false} text={text} handleTotal={handleTotal} content={total.deadline} special={language == 'ru' ? 'дней' : 'days'} name={language == 'ru' ? 'Срок выполнения' : 'Deadline'} inputName="deadline"/>
    </div>
  )
}

export default Totals