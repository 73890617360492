import { Card, Footer, Header, Lots, Totals } from "./components";

import {
  PDFDownloadLink,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";
import "./App.css";
import { useEffect, useState } from "react";
import { InputText } from "./UI/InputText";
import lotsInitial from "./components/Lots/lots.json";

Font.register({
  family: "Factor A",
  src: "/fonts/FactorA-Regular.ttf",
});

Font.register({
  family: "Factor A Bold",
  src: "/fonts/FactorA-Bold.ttf",
});

Font.register({
  family: "Factor A Medium",
  src: "/fonts/FactorAMedium-Regular.ttf",
});

Font.registerHyphenationCallback((word) => {
  const middle = Math.floor(word.length / 2);
  const parts =
    word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

  return parts;
});

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang') || 'ru';
  const cur = urlParams.get('cur') || 'rub';

  const [language, setLanguage] = useState(lang); // ru | en
  const [currency, setCurrency] = useState(cur); // rub | usd | eur

  const [title, setTitle] = useState(language == 'ru' ? "Разработка системы DOI для физических лиц" : 'Individual researchers DOI system development');

  const [monthMap, setMonthMap] = useState({
    0: language == 'ru' ? 'января' : 'January',
    1: language == 'ru' ? 'февраля' : 'February',
    2: language == 'ru' ? 'марта' : 'March',
    3: language == 'ru' ? 'апреля' : 'April',
    4: language == 'ru' ? 'мая' : 'May',
    5: language == 'ru' ? 'июня' : 'June',
    6: language == 'ru' ? 'июля' : 'July',
    7: language == 'ru' ? 'августа' : 'August',
    8: language == 'ru' ? 'сентября' : 'September',
    9: language == 'ru' ? 'октября' : 'October',
    10: language == 'ru' ? 'ноября' : 'November',
    11: language == 'ru' ? 'декабря' : 'December'
  });

  const [date, setDate] = useState(language == 'ru' ?
    `${new Date().getDate()} ${monthMap[new Date().getMonth()]} ${new Date().getFullYear()}`
    :
    `${monthMap[new Date().getMonth()]} ${ordinal_suffix_of(new Date().getDate())} ${new Date().getFullYear()}`);


  useEffect(() => {
    setMonthMap({
      0: language == 'ru' ? 'января' : 'January',
      1: language == 'ru' ? 'февраля' : 'February',
      2: language == 'ru' ? 'марта' : 'March',
      3: language == 'ru' ? 'апреля' : 'April',
      4: language == 'ru' ? 'мая' : 'May',
      5: language == 'ru' ? 'июня' : 'June',
      6: language == 'ru' ? 'июля' : 'July',
      7: language == 'ru' ? 'августа' : 'August',
      8: language == 'ru' ? 'сентября' : 'September',
      9: language == 'ru' ? 'октября' : 'October',
      10: language == 'ru' ? 'ноября' : 'November',
      11: language == 'ru' ? 'декабря' : 'December'
    });

    setDate(language == 'ru' ?
      `${new Date().getDate()} ${monthMap[new Date().getMonth()]} ${new Date().getFullYear()}`
      :
      `${monthMap[new Date().getMonth()]} ${ordinal_suffix_of(new Date().getDate())} ${new Date().getFullYear()}`);

    setTitle(language == 'ru' ? "Разработка системы DOI для физических лиц" : 'Individual researchers DOI system development');
  }, [language]);

  const [lots, setLots] = useState([
    {
      "id": "0",
      "name": language == 'ru' ? "Айдинтика бренда" : "Brand book",
      "hours": "25",
      "budget": "37 500",
      "content": language == 'ru' ? "Разработка и согласование айдентики бренда (в т.ч. логотип, фирменные цвета) в соответствии с видением заказчика и/или организатора проекта" : "Development and approval of brand identity (including logo, corporate colors) in accordance with the vision of the customer and/or project organizer"
    }
  ]);

  const [total, setTotal] = useState({
    team: 8,
    hours: '10',
    price: '25 000',
    deadline: '65'
  })

  useEffect(() => {
    const hours = lots.reduce((sum, lot) => sum += Number(lot.hours), 0);
    const priceRaw = lots.reduce((sum, lot) => sum += Number(lot.budget.replaceAll(' ', '').replaceAll(',', '')), 0).toString();

    var price = priceRaw.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");


    setTotal((total) => {
      return {
        ...total,
        hours: hours,
        price: price
      };
    })
  }, [lots]);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleTotal = ({ target }) => {
    console.log(target);
    setTotal({ ...total, [target.name]: target.value })
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#F9F9F9",
      paddingTop: 100,
      paddingBottom: 100,
      paddingHorizontal: 25,
    },
    header: {
      position: "absolute",
      paddingHorizontal: 25,
      top: 25,
      left: 0,
      right: 0,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Factor A",
      fontSize: "12px",
      width: "auto",
      color: "#9116CB",
    },
    title: {
      fontFamily: "Factor A Bold",
      fontSize: "30px",
      fontWeight: "semibold",
      width: "auto",
      color: "#300440",
      marginVertical: 30,
    },
    image: {
      height: 35,
      width: 150,
    },
    card: {
      borderRadius: 20,
      backgroundColor: "#ffffff",
      marginBottom: 50,
      paddingVertical: 8,
      border: 1,
      borderColor: "#e3e3e3",
    },
    lot: {
      flexDirection: "row",
      padding: 25,
      width: "100%",
      height: "auto",
      // borderRadius: 20,
      // backgroundColor: "#ffffff",
      // // marginBottom: 96,
      // paddingVertical: 8,
      // border: 1,
      // borderColor: "#e3e3e3",
    },
    lotLeftSide: {
      flexDirection: "column",
      width: "35%",
      marginRight: 20,
    },
    lotName: {
      fontFamily: "Factor A Medium",
      fontSize: 14,
      color: "#300440",
      marginBottom: 20,
    },
    lotBudget: {
      flexDirection: "row",
    },
    lotBudgetText: {
      fontFamily: "Factor A",
      fontSize: 12,
      width: "auto",
      color: "#9116CB",
    },
    lotBudgetDevider: {
      fontFamily: "Factor A",
      fontSize: "12px",
      width: "auto",
      color: "#9116CB",
      marginHorizontal: 10,
    },
    lotRightSide: {
      width: "65%",
    },
    lotText: {
      fontFamily: "Factor A",
      color: "#7E6786",
      fontSize: 16,
      height: "auto",
      textAlign: "left"
    },
    totalContainer: {
      flexDirection: "column",
      maxWidth: "55%"
    },
    totalTitle: {
      fontFamily: "Factor A Bold",
      fontSize: 20,
      color: "#300440",
      marginBottom: 20,
    },
    totalItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      paddingHorizontal: 20,
      paddingVertical: 15,
    },
    totalSubtitle: {
      fontFamily: "Factor A",
      color: "#300440",
      fontSize: 16,
      height: "auto",
    },
    totalText: {
      fontFamily: "Factor A Medium",
      fontSize: 16,
      height: "auto",
      color: "#9116CB",
    },
    footer: {
      flexDirection: "row",
      borderTop: "2px solid #e3e3e3",
      position: "absolute",
      left: 25,
      right: 25,
      bottom: 25,
      paddingTop: 20,
    },
    footerBlock: {
      flexDirection: "column",
      width: "25%",
      // paddingHorizontal: 10,
      alignItems: "flex-start",
      justifyContent: "center"
    },
    footerText: {
      fontFamily: "Factor A Medium",
      fontSize: "18px",
      width: "auto",
      color: "#300440",
      marginTop: 10,
    },
    horizontalDivider: {
      width: "100%",
      height: 50
    }
  });

  const MyDocument = ({ language, date, title }) => {
    return (
      <Document language="RU-ru">
        <Page size="TABLOID" style={styles.page} wrap={false}>
          <View style={styles.header} fixed>
            <Image style={styles.image} src="/images/logo.png" alt="logo" />
            <Text style={styles.headerText}>
              {language == 'ru' ? 'Коммерческое предложение от' : 'Offer from'}&nbsp;{date}
            </Text>
          </View>
          <View style={styles.title}>
            <Text>{title}</Text>
          </View>
          <View style={styles.horizontalDivider}></View>
          <View style={styles.card}>
            {lots.map((el, idx) => {
              el.budget = el.budget.replaceAll(' ', '').replaceAll(',', ' ').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
              return (
                <View
                  key={el.id}
                  style={[
                    styles.lot,
                    idx + 1 < lots.length
                      ? { borderBottom: "2px solid #e3e3e3" }
                      : {},
                  ]}
                >
                  <View style={styles.lotLeftSide} wrap>
                    <Text style={styles.lotName}>{el?.name || ""}</Text>
                    <View style={styles.lotBudget}>
                      <Text style={styles.lotBudgetText}>
                        {language == 'ru' ? 'часы' : 'hours'} {el?.hours || "0"}
                      </Text>
                      <Text style={styles.lotBudgetDevider}>/</Text>
                      <Text style={styles.lotBudgetText}>
                        {language == 'ru' ? 'бюджет' : 'budget'} {currency != 'rub' ? (currency == 'usd' ? '$' : '€') : null}{el?.budget || "0"}{currency == 'rub' ? '₽' : null} &#8381;
                      </Text>
                    </View>
                  </View>
                  <View style={styles.lotRightSide} wrap>
                    <Text style={styles.lotText}>{el.content}</Text>
                  </View>
                </View>
              )
            })}
          </View>

          <View style={styles.totalContainer}>
            <Text style={styles.totalTitle}>{language == 'ru' ? 'Итог' : 'Total'}</Text>
            <View style={styles.card}>
              <View style={[styles.totalItem, { borderBottom: "1px solid #e3e3e3" }]}>
                <Text style={styles.totalSubtitle}>{language == 'ru' ? 'Команда' : 'Team'}</Text>
                <Text style={styles.totalText}>{total.team} </Text>
              </View>
              <View style={[styles.totalItem, { borderBottom: "1px solid #e3e3e3" }]}>
                <Text style={styles.totalSubtitle}>{language == 'ru' ? 'Часы' : 'Hours'}</Text>
                <Text style={styles.totalText}>{total.hours} {language == 'ru' ? 'часов' : 'hours'}</Text>
              </View>
              <View style={[styles.totalItem, { borderBottom: "1px solid #e3e3e3" }]}>
                <Text style={styles.totalSubtitle}>{language == 'ru' ? 'Стоимость' : 'Budget'}</Text>
                <Text style={styles.totalText}>{currency != 'rub' ? (currency == 'usd' ? '$' : '€') : null}{total.price} {currency == 'rub' ? 'рублей' : null}</Text>
              </View>
              <View style={styles.totalItem}>
                <Text style={styles.totalSubtitle}>{language == 'ru' ? 'Срок выполнения' : 'Deadline'}</Text>
                <Text style={styles.totalText}>{total.deadline} {language == 'ru' ? 'дней' : 'days'}</Text>
              </View>
            </View>
          </View>

          <View style={styles.footer} fixed>
            <View style={styles.footerBlock}>
              <Image style={styles.image} src="/images/logo.png" alt="logo" />
            </View>
            <View style={styles.footerBlock}>
              <Text style={styles.headerText}>{language == 'ru' ? 'сайт' : 'website'}</Text>
              <Link style={{ textDecoration: "none" }} src="https://softndit.com">
                <Text style={styles.footerText}>softndit.com</Text>
              </Link>
            </View>
            <View style={styles.footerBlock}>
              <Text style={styles.headerText}>e-mail</Text>
              <Text style={styles.footerText}>hi@softndit.com</Text>
            </View>
            <View style={styles.footerBlock}>
              <Text style={styles.headerText}>{language == 'ru' ? 'телефон' : 'phone'}</Text>
              <Text style={styles.footerText}>8 800 200 48 30</Text>
            </View>
          </View>

          <View style={styles.horizontalDivider}></View>
        </Page>
      </Document>
    );
  };

  const onAddLot = () => {
    setLots((prev) => [
      ...prev,
      {
        budget: "",
        content: "",
        hours: "",
        name: "",
        id: new Date().getTime() + Math.floor(Math.random() * 5),
      },
    ]);
  };

  const updateLot = (id, attr, data) => {
    const updatedLots = lots.map((el, idx) => {
      if (el.id === id) {
        if (attr == 'budget') {
          el[attr] = data.replaceAll(' ', '').replaceAll(',', ' ').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
        } else {
          el[attr] = data;
        }
      }
      return el;
    });

    setLots([...updatedLots]);
  };

  const onRemoveLot = (id) => {
    let lotsUpdated = [...lots];
    const i = lots.findIndex((l) => l.id == id);
    if (i != -1) {
      lotsUpdated.splice(i, 1);
    }

    setLots(lotsUpdated);
  };

  const onDownload = () => {
    setIsDownloading((prev) => !prev);
  };

  useEffect(() => {
    if (isDownloading) {
      setIsDownloading(false);
    }
  }, [isDownloading]);

  return (
    <div className="App">
      <Header setDate={setDate} date={date} text={isDownloading} />

      {isDownloading ? null : (
        <Card style={{ maxWidth: "709px" }}>
          <a target="_blank" href="/example.pdf" className="Download">
            {language == 'ru' ? 'Пример' : 'Example'}
          </a>
        </Card>
      )}

      <h1 className="top">
        <InputText
          text={isDownloading}
          multiline
          setValue={(e) => setTitle(e.target.value)}
          value={title}
        />
      </h1>
      <Card>
        <Lots
          language={language}
          currency={currency}
          text={isDownloading}
          lots={lots}
          updateLot={updateLot}
          onRemoveLot={(id) => {
            onRemoveLot(id);
            setTitle(title);
          }}
          onAddLot={onAddLot}
        />
      </Card>
      <h2 className="tt">{language == 'ru' ? 'Итог' : 'Total'}</h2>
      <Card style={{ maxWidth: "709px" }}>
        <Totals language={language} currency={currency} text={isDownloading} total={total} handleTotal={handleTotal} />
      </Card>

      {isDownloading ? null : (
        <>
          {/* <Card style={{ maxWidth: "709px" }}>
            <span onClick={onDownload} className="Download">
              Скачать
            </span>
          </Card> */}
          <Card style={{ maxWidth: "709px", display: "flex" }}>
            <PDFDownloadLink
              language={language}
              style={{ margin: "0 auto", textDecoration: "none" }}
              document={<MyDocument date={date} language={language} title={title} />}
              fileName={language == 'ru' ? `Soft'n'd - коммерческое предложение ${date}` : `Soft'n'd - offer from ${date}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Документ формируется..." : "Скачать"
              }
            </PDFDownloadLink>
          </Card>
        </>
      )}
      <Footer />
    </div>
  );
}

export default App;
